import { Outlet } from '@remix-run/react';

import { PortalLink } from '../components/PortalContext';
import { AdminView } from '../pages/Admin/AdminView';
import { Wrapper } from '../pages/Minigame';

function AdminMinigameShellRoute() {
  return (
    <AdminView
      search={{
        targetPathname: '/admin/minigames/search',
        placeholder: 'Search Minigames',
      }}
      className='bg-library-2023-07'
    >
      <Wrapper routePrefix={PortalLink.AdminHome} mode='admin'>
        <Outlet />
      </Wrapper>
    </AdminView>
  );
}

export const Component = AdminMinigameShellRoute;

export function clientLoader() {
  return null;
}
